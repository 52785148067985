// Core
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Seo } from "../components"

const PopeyesPage = () => (
  <Layout>
    <Wrapper>
      <Seo title="Popeyes" />
      <section className="brand">
        <h4 className="brand__heading">Customer story</h4>
        <h1 className="brand__title">
          How Outloud.ai Transformed the Operations of Popeyes Restaurants in
          the Midwest
        </h1>
        <p className="brand__text brand__text--italic">
          Miami, FL, Feb. 20, 2023 - with their unique proprietary voice
          artificial intelligence technology, Outloud.ai has been steadily
          helping fast-food restaurants improve their operations. One such story
          of transformation is of a Popeyes operator in the Midwest who oversaw
          30+ stores.
        </p>
        <StaticImage
          className="brand__img"
          src="../assets/brand-menu.png"
          alt="Popeyes food menu"
          quality="90"
          layout="fullWidth"
          placeholder="blurred"
        />
        <p className="brand__text">
          The journey began with the integration of Outloud's system into eight
          pilot Popeyes locations in May 2022. The need for such a change was
          rooted in a desire to optimize operations and increase profitability,
          but the scope of impact was much larger.
        </p>
        <p className="brand__text">
          "Our main goal has always been to provide valuable insights and help
          fast-food restaurants solve issues in real-time using AI technology.
          We focus on recognizing voice conversations even in noisy
          environments, providing actionable insights for improving customer
          service, and aligning employee incentives," shares Sasha Miagkyi, the
          founder and CEO of Outloud.ai.
        </p>
        <section className="brand__row">
          <section className="brand__description">
            <h3 className="brand__subtitle">
              Understanding Outloud's Alert System:
            </h3>
            <p className="brand__text">
              An "alert" in Outloud's context is a real-time notification
              generated by their system indicating potential operational issues
              or opportunities for improvement. These could range from
              out-of-stock notifications, slow service times, ready food not
              being served promptly, to equipment malfunction.
            </p>
            <p className="brand__text">
              "When we started in May 2022, we noticed an average of about 150
              alerts per store per month. To give an example, one type of alert
              might indicate that in the last 12 minutes, an order-taker had
              told eight guests that the POS system was down. These alerts were
              designed to give a comprehensive overview of potential hiccups
              that could be affecting the store's efficiency and customer
              experience," explains Miagkyi.
            </p>
          </section>
          <StaticImage
            className="brand__img"
            src="../assets/brand-alert.png"
            alt="Popeyes alert details"
            quality="90"
            layout="fullWidth"
            placeholder="blurred"
          />
        </section>
        <h3 className="brand__subtitle">
          Preliminary Outcomes and Expansions:
        </h3>
        <p className="brand__text">
          Upon implementing Outloud's system, approximately 50% of the issues
          identified were related to out-of-stock situations, 20% were cases of
          food not being ready on time, and 10% were tied to service speed. The
          rest of the alerts identified other issues like equipment breakdowns.
        </p>
        <p className="brand__text">
          Over the course of nine months, the average{" "}
          <span className="brand__text--highlight">
            number of monthly alerts per store decreased from 146 to 29,
          </span>{" "}
          indicating a significant improvement in operational efficiency. This
          transformation was a result of the actionable insights provided by
          Outloud, enabling store managers to address issues proactively.
        </p>
        <p className="brand__text">
          Recognizing the substantial benefits, the operator made a decision to
          expand Outloud's system to the rest of their Popeyes stores.
        </p>
        <h3 className="brand__subtitle">
          Financial Impact and Customer Satisfaction:
        </h3>
        <p className="brand__text">
          The significant reduction in alerts directly contributed to cost
          savings, with thousands of dollars saved per store each month. This
          boost in operational efficiency had a ripple effect, leading to an
          increase in overall revenue and profitability for each Popeyes
          location.
        </p>
        <p className="brand__text">
          <span className="brand__text--highlight">
            "Each alert, basically, means $15 to $250 lost in unearned revenue,
          </span>{" "}
          unhappy customers and slow service. By reducing the frequency of
          equipment breakdowns, out-of-stock situations and improving service
          speed, we were able to enhance the customer experience, leading to
          more return customers and higher overall customer satisfaction
          ratings," states Miagkyi.
        </p>
        <p className="brand__text">
          Moreover, better inventory management meant less wastage, more
          efficient use of resources, and ultimately, improved bottom-line
          results.
        </p>
        <p className="brand__text">
          As Sasha Miagkyi concludes, "The Popeyes story is a testament to the
          power of AI in revolutionizing fast-food operations. It's not just
          about the cost savings or the increased revenue -{" "}
          <span className="brand__text--highlight">
            it's about creating a better customer experience, empowering and
            incentivizing employees, and ultimately, building a more efficient,
            effective business"
          </span>
        </p>
        <p className="brand__text brand__text--italic">
          This information is provided for general informational purposes only,
          and publication does not constitute an endorsement. Outloud.ai does
          not warrant the accuracy or completeness of any information, text,
          graphics, links, or other items contained within this content.
          Outloud.ai does not guarantee you will achieve any specific results if
          you follow any advice herein. It may be advisable for you to consult
          with a professional such as a lawyer, accountant, or business advisor
          for advice specific to your situation. Individual results may vary.
        </p>
      </section>
    </Wrapper>
  </Layout>
)

export default PopeyesPage
